<div class="app-i18n" (clickOutside)="onClickOutSide()">
   <div class="lang-selected" (click)="showLangList = !showLangList">
      <!-- <img [attr.src]="'./assets/images/'+selectedLanguage+'.svg'"> -->
      {{selectedLanguage | uppercase}}
      <img src="./assets/images/dd-arrow.svg">
      <div class="dd-arrow"></div>
   </div>
   <div class="lang-list" *ngIf="showLangList">
      <a *ngFor="let lang of supportedLanguages" (click)="switchLanguage(lang)">
      <!-- <img [attr.src]="'./assets/images/'+lang+'.svg'"> -->{{lang | uppercase}}
      </a>
      <!-- <a><img src="../../assets/images/united-states-of-america.svg"> EN</a>
         <a><img src="../../assets/images/united-states-of-america.svg"> FR</a>
         <a><img src="../../assets/images/united-states-of-america.svg"> DE</a> -->
   </div>
</div>