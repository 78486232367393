import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  @Input()
  status: string;

  @Input()
  message: string;

  mappings = {
    'The session id is invalid or expired.': 'SESSION_INVALID',
    'Request is not applicable for the verification status associated to the session': 'BAD_REQUEST',
    'Session Expired': 'SESSION_EXPIRED',
    'Internal Error': 'INTERNAL_ERROR'
  };

  constructor() { }

  ngOnInit() {
    this.setMessage();
  }

  setMessage() {
    console.log(this.message);
    if (this.mappings[this.message]) {
      this.message = this.mappings[this.message];
    }
  }

}
