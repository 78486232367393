import { UtilService } from './../services/util.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService, BankAccounts, BankAccountSumbitResponse } from '../services/config.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-verifiedme-page',
  templateUrl: './verifiedme-page.component.html',
  styleUrls: ['./verifiedme-page.component.scss']
})
export class VerifiedmePageComponent implements OnInit {

  loadHeader: boolean;
  showNoAccounts: boolean;
  loading: boolean;
  verifiedLoader: boolean;
  loaderSubmitAccounts: boolean;
  showModal: boolean;
  bankAccounts: BankAccounts;
  accountId: string;
  showError: boolean;
  showAccountLinked: boolean;
  showAccountNotLinked: boolean;
  bankSchemeType: string;
  error: any;
  showLoginNotSupported: boolean;
  loginNotSupported: boolean;
  checkInitialStatusInterval = 3500;
  checkStatusInterval = 3000;
  submitInterval = 2000;
  checkStatusIntervalTimeout = 300000;
  timerEnabled = false;
  checkStatusIntervalTimerid = null;
  checkStatusTimeOutTimerid = null;

  constructor(private route: ActivatedRoute,
    private configService: ConfigService,
    private router: Router,
    private util: UtilService) { }

  ngOnInit() {
    this.util.loadLanguage(this.route.snapshot.queryParamMap.get('language'));
    this.showLoginNotSupported = this.route.snapshot.queryParamMap.get('validLoginType') === 'true' ? false : true;
    // TODO: we wont use this accountId because we might get multiple accounts
    // this.accountId = this.route.snapshot.queryParamMap.get('id') === undefined ? undefined : this.route.snapshot.queryParamMap.get('id');
    this.loadPage();
  }

  loadPage() {
    this.initialize();
    if (this.showLoginNotSupported) {
      this.loginNotSupported = true;
      setTimeout(() => this.submitAccount('LOGIN_NOT_SUPPORTED'), this.submitInterval);
    } else {
      setTimeout(() => this.checkVerificationStatus(), this.checkInitialStatusInterval);
    }
  }

  hideModel() {
    this.showModal = false;
  }

  popUpModel() {
    this.showModal = true;
  }

  enableLoader() {
    this.loading = true;
    this.verifiedLoader = true;
  }

  disableLoader() {
    this.loading = false;
    this.verifiedLoader = false;
    this.loaderSubmitAccounts = false;
  }

  populateError(error: HttpErrorResponse) {
    this.error = {};
    this.showError = true;
    if (error.error && error.error.status) {
      this.error['status'] = error.error.status;
    } else {
      this.error['status'] = error.status;
    }

    if (error.error && error.error.reason) {
      this.error['message'] = error.error.reason;
    } else if (error.error && error.error.error && error.error.error.message) {
      this.error['message'] = error.error.error.message;
    } else {
      this.error['message'] = error.statusText;
    }

    if (error.error && error.error.links) {
      this.redirectToUrl(error.error.links[0].href);
    } else {
      this.disableLoader();
    }
  }


  initialize() {
    this.loadHeader = false;
    this.enableLoader();
    // this.verifiedLoader = true;
    // this.loaderSubmitAccounts = false;
    this.hideModel();
    this.error = null;
    // this.accountId = null;
    this.bankAccounts = null;
    this.showError = false;
    this.showNoAccounts = false;
    this.showAccountNotLinked = false;
    this.showAccountLinked = false;
  }


  onClickCancel() {
    this.popUpModel();
  }

  modelActionPerformed(action: boolean) {
    if (action) {
      this.submitAccount('CANCEL');
    }
    this.hideModel();
  }

  checkVerificationStatus() {
    this.configService.checkVerifiedmeStatus()
      .subscribe((response: HttpResponse<any>) => {
        if (response.body.status === 'PENDING') {
          this.checkStatusIsPending();
        } else if (response.body.status === 'WAITING') {
          this.stopTimer();
          // this.getBankAccountDetails();
          setTimeout(() => this.submitAccount('SUBMIT'), this.submitInterval);
        } else {
          this.stopTimer();
          setTimeout(() => this.submitAccount('ERROR'), this.submitInterval);
        }
      }, error => {
        this.stopTimer();
        this.disableLoader();
        console.log(error);
        this.populateError(error);
      });
  }

  startTimer() {
    this.timerEnabled = true;
    this.checkStatusIntervalTimerid = setInterval(() => this.checkVerificationStatus(), this.checkStatusInterval);
  }

  stopTimer() {
    this.timerEnabled = false;
    clearTimeout(this.checkStatusIntervalTimerid);
    clearTimeout(this.checkStatusTimeOutTimerid);
  }

  checkStatusIsPending() {
    if (!this.timerEnabled) {
      this.startTimer();
      this.checkStatusTimeOutTimerid = setTimeout(() => this.submitTimeOutAccout(), this.checkStatusIntervalTimeout);
    }
  }

  submitTimeOutAccout() {
    if (this.timerEnabled) {
      this.stopTimer();
      setTimeout(() => this.submitAccount('ERROR'), this.submitInterval);
    }
  }

  submitAccount(action: string) {
    if (!this.showNoAccounts) {
      this.enableLoader();
      if (action === 'SUBMIT') {
        this.verifiedLoader = false;
        this.loaderSubmitAccounts = true;
      }
    }
    this.configService.submitVerifiedme(action)
      .subscribe((response: HttpResponse<BankAccountSumbitResponse>) => {
        this.disableLoader();
        this.showResultToUser(response.body);
      }, error => {
        this.disableLoader();
        console.log(error);
        this.populateError(error);
      });
  }

  showResultToUser(data: BankAccountSumbitResponse) {
    this.resetData();
    if (data.redirectUrl.includes('SUCCESS')) {
      this.showAccountLinked = true;
    } else {
      this.showAccountNotLinked = !this.showNoAccounts;
    }
    this.redirectToUrl(data.redirectUrl);
  }

  redirectToUrl(url: string) {
    setTimeout(() => window.location.href = url, 3000);
  }

  resetData() {
    this.loadHeader = false;
  }

}
