import { UtilService, SUPPORTED_LANGUAGES } from './../services/util.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-language-selact',
  templateUrl: './language-selact.component.html',
  styleUrls: ['./language-selact.component.scss']
})
export class LanguageSelactComponent implements OnInit {

  supportedLanguages = SUPPORTED_LANGUAGES;
  selectedLanguage: string;
  showLangList: boolean;

  constructor(private util: UtilService) { }

  ngOnInit() {
    this.selectedLanguage = localStorage.getItem('lang');
  }

  switchLanguage(lang: string) {
    this.selectedLanguage = lang;
    this.util.loadLanguage(lang);
    this.showLangList = false;
  }

  onClickOutSide() {
    this.showLangList = false;
  }
}
