import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

const XSERVICEMODE = 'X-Microservice-Mode';
const LIVE = 'LIVE';



export class BankAccounts {
  eft: Eft[];
  ach: Ach[];
  sepa: Sepa[];
  bacs: Bacs[];
  wire: Wire[];
  sessionId: string;
}
export class Ach {
  accountHolderName: string;
  routingNumber: string;
  lastDigits: string;
  accountType: string;
  paymentToken: string;
  currency: string;
  accountNumber: string;
  title: string;
  balance: string;
}
export class Eft {
  accountHolderName: string;
  institutionId: string;
  transitNumber: string;
  lastDigits: string;
  accountType: string;
  paymentToken: string;
  currency: string;
  accountNumber: string;
}
export class Sepa {
  accountHolderName: string;
  ibanNumber: string;
  swiftNumber: string;
  lastDigits: string;
  accountType: string;
  paymentToken: string;
  currency: string;
  accountNumber: string;
}
export class Bacs {
  accountHolderName: string;
  sortCode: string;
  lastDigits: string;
  accountType: string;
  paymentToken: string;
  currency: string;
  accountNumber: string;
}
export class Wire {
  accountHolderName: string;
  sortCode: string;
  routingNumber: string;
  swiftNumber: string;
  ibanNumber: string;
  lastDigits: string;
  accountType: string;
  paymentToken: string;
  currency: string;
  accountNumber: string;
}

export class BankAccountSumbitResponse {
  status: string;
  redirectUrl: string;
}

@Injectable()
export class ConfigService {

  constructor(private http: HttpClient) { }

  submitSelectedAccount(data: any): Observable<HttpResponse<BankAccountSumbitResponse>> {
    let url = '/api/bankaccountvalidator/v1/internal/submit';
    if (environment.apiUrl) {
      url = environment.apiUrl + url;
    }
    const headers = new HttpHeaders().set(XSERVICEMODE, LIVE);
    return this.http.post<BankAccountSumbitResponse>(url,
      data, { headers, observe: 'response' });
  }

  submitVerifiedme(data: any): Observable<HttpResponse<BankAccountSumbitResponse>> {
    let url = '/api/bankaccountvalidator/v1/internal/verifiedme/submit';
    if (environment.apiUrl) {
      url = environment.apiUrl + url;
    }
    const headers = new HttpHeaders().set(XSERVICEMODE, LIVE);
    return this.http.post<BankAccountSumbitResponse>(url,
      data, { headers, observe: 'response' });
  }

  getAccounts(): Observable<HttpResponse<BankAccounts>> {
    let url = '/api/bankaccountvalidator/v1/internal/getAccounts';
    if (environment.apiUrl) {
      url = environment.apiUrl + url;
    }
    const headers = new HttpHeaders().set(XSERVICEMODE, LIVE);
    return this.http.get<BankAccounts>(url,
      { headers, observe: 'response' });
  }

  checkVerificationStatus(): Observable<HttpResponse<any>> {
    let url = '/api/bankaccountvalidator/v1/internal/status';
    if (environment.apiUrl) {
      url = environment.apiUrl + url;
    }
    return this.http.get<any>(url,
      { observe: 'response' });
  }

  checkVerifiedmeStatus(): Observable<HttpResponse<any>> {
    let url = '/api/bankaccountvalidator/v1/internal/verifiedme/status';
    if (environment.apiUrl) {
      url = environment.apiUrl + url;
      console.log(url);
    }
    return this.http.get<any>(url,
      { observe: 'response' });
  }
}
