<!-- Modal -->
<div class="modal fade show custom-modal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
          <a href="#" aria-label="Close"  (click)="onSubmit(false)" class="modal-close">&times;</a>
        <iframe id="iframe" scrolling="no"  class="dimensions" [src]="redirectUrl | safe" allowfullscreen
          frameborder="0"></iframe>
      </div>
    </div>
  </div>
</div>
<div class="modal-bg"></div>