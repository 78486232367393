<app-header *ngIf="loadHeader"></app-header>
<div *ngIf="bankAccounts">
    <div class="main-content">
        <div class="outer-wrapper">
            <div class="info-wrapper">
                <app-info-card [bankAccounts]="bankAccounts" [accountType]="bankSchemeType" (selectedId)="getSelectedAccountId($event)"></app-info-card>
            </div>
            <div class="button-wrapper">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <button class="btn btn-outline-primary" (click)="onClickCancel()">{{'CANCEL' | translate}}</button>
                    </div>
                    <div class="col-12 col-sm-6 text-right">
                        <button class="btn btn-primary" (click)="onClickSubmit()" [disabled]="!accountId">{{'SUBMIT' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-modal *ngIf="showModal" (userAction)="modelActionPerformed($event)" ></app-modal>
<app-error *ngIf="showError" [status]="error.status" [message]="error.message" ></app-error>
<app-no-accounts *ngIf="showNoAccounts || loginNotSupported" [noAccountFound]="showNoAccounts" [loginNotSupported]="loginNotSupported"></app-no-accounts>
<app-no-accounts-linked *ngIf="showAccountNotLinked"></app-no-accounts-linked>
<app-account-linked *ngIf="showAccountLinked"></app-account-linked>
<app-loader *ngIf="loading" [fetchAccounts]="loaderFetchAccounts" [linkAccount]="loaderSubmitAccounts"></app-loader>