import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

const LANGUAGES = ['en', 'fr', 'da', 'nl', 'de', 'it', 'no', 'pl', 'es', 'sv'];

export const SUPPORTED_LANGUAGES = LANGUAGES;

@Injectable()
export class UtilService {

  supportedLanguages = LANGUAGES;
  selectedLanguage: string;

  constructor(private translateService: TranslateService) {

  }

  private loadDefaultLanguage() {
    this.translateService.use('en');
    localStorage.setItem('lang', 'en');
  }

  private loadUserLanguage(lang: string) {
    this.translateService.use(lang);
    localStorage.setItem('lang', lang);
  }

  loadBrowserLanguage() {
    this.loadLanguage(localStorage.getItem('lang'));
  }

  loadLanguage(lang: string) {
    if (lang && this.supportedLanguages.indexOf(lang) !== -1) {
      this.loadUserLanguage(lang);
    } else {
      this.loadDefaultLanguage();
    }
  }
}

