import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-no-accounts',
  templateUrl: './no-accounts.component.html',
  styleUrls: ['./no-accounts.component.scss']
})
export class NoAccountsComponent {

  @Input()
  noAccountFound: boolean;

  @Input()
  loginNotSupported: boolean;

  constructor() { }

}
