<!-- <div class="toggle-btn">
  <p>Show Amount</p>
  <input type="checkbox" id="showAmount" class="check-box hidden" [(ngModel)]="showAmount"/>
  <label for="showAmount" class="label"></label>
</div> -->
<div class="info-card-container">
  <ng-template ngFor let-item [ngForOf]="data" let-i="index">
  <div class="row">
    <div class="col-12">
      <div class="card card-wrapper" [class.selected]="item.paymentToken === selectedAccountId">
        <label [attr.for]="'radio' + i">
          <div class="card-body">
            <div>
                <div class="row">
                  <div class="col-12"><h4>{{ item.accountHolderName | titlecase }}</h4></div>
                  <div class="col-6 col-sm-4">
                    <span class="label-text">{{'ACCOUNT' | translate }}</span>
                    <span class="label-value">{{ item.lastDigits }}</span>
                  </div>
                  <div class="col-6 col-sm-4" *ngIf="item.title">
                    <span class="label-text">{{'TITLE' | translate }}</span>
                    <span class="label-value"> {{ item.title }}</span>
                  </div>
                  <div class="col-6 col-sm-4" *ngIf="item.institutionId">
                    <span class="label-text">{{'INSTITUTION' | translate }}</span>
                    <span class="label-value">{{ item.institutionId }}</span>
                  </div>
                  <div class="col-6 col-sm-4" *ngIf="item.transitNumber">
                    <span class="label-text">{{'TRANSIT_NUMBER' | translate }}</span>
                    <span class="label-value">{{ item.transitNumber }}</span>
                  </div>
                  <div class="col-6 col-sm-4" *ngIf="item.routingNumber">
                    <span class="label-text">{{'ROUTING_NUBER' | translate }}</span>
                    <span class="label-value"> {{ item.routingNumber }}</span>
                  </div>
                  <div class="col-6 col-sm-4" *ngIf="item.accountType">
                    <span class="label-text">{{'ACCOUNT_TYPE' | translate }}</span>
                    <span class="label-value">{{ item.accountType | translate }}</span>
                  </div>
                  <div class="col-6 col-sm-4" *ngIf="item.ibanNumber">
                    <span class="label-text">{{'IBAN_NUMBER' | translate }}</span>
                    <span class="label-value">{{ item.ibanNumber }}</span>
                  </div>
                  <div class="col-6 col-sm-4" *ngIf="item.swiftNumber">
                    <span class="label-text">{{'SWIFT_NUMBER' | translate }}</span>
                    <span class="label-value">{{ item.swiftNumber }}</span>
                  </div>
                  <div class="col-6 col-sm-4" *ngIf="item.sortCode">
                    <span class="label-text">{{'SORT_CODE' | translate }}</span>
                    <span class="label-value">{{ item.sortCode }}</span>
                  </div>
                  <div class="col-6 col-sm-4">
                    <span class="label-text">{{'ACCOUNT_CURRENCY' | translate }}</span>
                    <span class="label-value">{{ item.currency }}</span>
                  </div>
                  <div class="col-6 col-sm-4" *ngIf="item.balance">
                    <span class="label-text">{{'ACCOUNT_BALANCE' | translate }}</span>
                    <span class="label-value">{{ item.balance }}</span>
                  </div>
                  <!-- <div class="col-6 col-sm-4">
                    <span class="label-text">{{'ACCOUNT_NUMBER' | translate }}</span>
                    <span class="label-value"> {{ item.accountNumber }}</span>
                  </div> -->
                </div>
              </div>
            <div class="radio-btn">
              <input type="radio" [attr.id]="'radio' + i" name="radio-button" [value]="item.accountNumber"
                [(ngModel)]="selectedAccountId" (change)="onItemChange()">
                <!-- ng-checked="i === 0" -->
            </div>
          </div>
        </label>
      </div>
    </div>
  </div>
    <!-- <div class="row">
      <div class="col-12">
        <div class="card card-wrapper">
          <label [attr.for]="radio2">
            <div class="card-body">
              <h4>John Doe (Combination)</h4>
              <p class="currency">USD $50,005.10</p>
              <p>Institution: Royal Bank of Canada</p>
              <p>Transit: 46478867</p>
              <div class="radio-btn">
                <input type="radio" id="radio2" name="radio-button">
              </div>
            </div>
          </label>
        </div>
      </div>
    </div> -->
  </ng-template>
</div>
