import { UtilService } from './../services/util.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-error-redirect',
  templateUrl: './error-redirect.component.html',
  styleUrls: ['./error-redirect.component.scss']
})
export class ErrorRedirectComponent implements OnInit {

  status: string;

  message: string;

  mappings = {
    'invalidSession': 'SESSION INVALIDE-CONTACTER L\'ÉQUIPE D\'ASSISTANCE/SESSION INVALID-CONTACT SUPPORT TEAM',
    'internalError': 'Erreur interne/Internal Error'
  };

  constructor(private route: ActivatedRoute, private util: UtilService) {}
  ngOnInit() {
    this.util.loadBrowserLanguage();
    this.status = this.route.snapshot.params['status'];
    this.setMessage();
  }

  setMessage() {
    if (this.status && this.mappings[this.status]) {
      this.message = this.mappings[this.status];
    }
  }
}
