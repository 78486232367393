import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AppComponent } from './app.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { AppRoutingModule } from './app.routing.module';
import { HeaderComponent } from './header/header.component';
import { InfoCardComponent } from './info-card/info-card.component';
import { NoAccountsComponent } from './no-accounts/no-accounts.component';
import { AccountLinkedComponent } from './account-linked/account-linked.component';
import { ModalComponent } from './modal/modal.component';
import { LanguageSelactComponent } from './language-selact/language-selact.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ConfigService } from './services/config.service';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoaderComponent } from './loader/loader.component';
import { NoAccountsLinkedComponent } from './no-accounts-linked/no-accounts-linked.component';
import { TestFlowComponent } from './test-flow/test-flow.component';
import { TestServiceService } from './services/test-service.service';
import { FlinksModelComponent } from './flinks-model/flinks-model.component';
import { SafePipe } from './flinks-model/safe-pipe.component';
import { ErrorComponent } from './error/error.component';
import { ErrorRedirectComponent } from './error-redirect/error-redirect.component';
import { UtilService } from './services/util.service';
import { VerifiedmePageComponent } from './verifiedme-page/verifiedme-page.component';
import { VerifiedmeNoUserinfoComponent } from './verifiedme-no-userinfo/verifiedme-no-userinfo.component';
import { VerifiedmeRedirectionComponent } from './verifiedme-redirection/verifiedme-redirection.component';
import { VerifiedmeLoaderComponent } from './verifiedme-loader/verifiedme-loader.component';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    VerifiedmePageComponent,
    HeaderComponent,
    InfoCardComponent,
    NoAccountsComponent,
    VerifiedmeNoUserinfoComponent,
    VerifiedmeRedirectionComponent,
    AccountLinkedComponent,
    ModalComponent,
    LanguageSelactComponent,
    ClickOutsideDirective,
    TestFlowComponent,
    PageNotFoundComponent,
    LoaderComponent,
    VerifiedmeLoaderComponent,
    NoAccountsLinkedComponent,
    FlinksModelComponent,
    SafePipe,
    ErrorComponent,
    ErrorRedirectComponent
  ],
  exports: [
    ClickOutsideDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [ConfigService, TestServiceService, UtilService],
  bootstrap: [AppComponent]
})
export class AppModule { }
