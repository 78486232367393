import { Component } from '@angular/core';

@Component({
  selector: 'app-verifiedme-redirection',
  templateUrl: './verifiedme-redirection.component.html',
  styleUrls: ['./verifiedme-redirection.component.scss']
})
export class VerifiedmeRedirectionComponent {

  constructor() { }
}
