import { BankAccounts } from './../services/config.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent implements OnInit {

  @Input()
  bankAccounts: BankAccounts;

  @Input()
  accountType: string;

  data: any;

  showAmount: boolean;

  @Output()
  selectedId = new EventEmitter();

  selectedAccountId: string;

  constructor() { }

  ngOnInit() {
    this.showAmount = false;
    if (this.accountType === 'EFT') {
      this.data = this.bankAccounts.eft;
    } else if (this.accountType === 'ACH')  {
      this.data = this.bankAccounts.ach;
    } else if (this.accountType === 'BACS')  {
      this.data = this.bankAccounts.bacs;
    } else if (this.accountType === 'WIRE')  {
      this.data = this.bankAccounts.wire;
    } else if (this.accountType === 'SEPA')  {
      this.data = this.bankAccounts.sepa;
    }
  }

  onItemChange() {
    this.selectedId.emit(this.selectedAccountId);
  }

}
