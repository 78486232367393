import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input()
  fullHeight;

  @Input()
  fetchAccounts: boolean;
  @Input()
  linkAccount: boolean;

  extendHeight;

  constructor() { }

  ngOnInit(): void {
    this.fixHeight();
  }

  fixHeight() {
    if (this.fullHeight) {
      this.extendHeight = true;
    } else {
      this.extendHeight = false;
    }
  }
}



