import { UtilService } from './../services/util.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService, BankAccounts, BankAccountSumbitResponse } from '../services/config.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  loadHeader: boolean;
  showNoAccounts: boolean;
  loading: boolean;
  loaderFetchAccounts: boolean;
  loaderSubmitAccounts: boolean;
  showModal: boolean;
  bankAccounts: BankAccounts;
  accountId: string;
  showError: boolean;
  showAccountLinked: boolean;
  showAccountNotLinked: boolean;
  bankSchemeType: string;
  error: any;
  showLoginNotSupported: boolean;
  loginNotSupported: boolean;
  checkInitialStatusInterval = 3500;
  checkStatusInterval = 3000;
  submitInterval = 2000;
  checkStatusIntervalTimeout = 300000;
  timerEnabled = false;
  checkStatusIntervalTimerid = null;
  checkStatusTimeOutTimerid = null;

  constructor(private route: ActivatedRoute,
    private configService: ConfigService,
    private router: Router,
    private util: UtilService) { }

  ngOnInit() {
    this.util.loadLanguage(this.route.snapshot.queryParamMap.get('language'));
    this.showLoginNotSupported = this.route.snapshot.queryParamMap.get('validLoginType') === 'true' ? false : true;
    // TODO: we wont use this accountId because we might get multiple accounts
    // this.accountId = this.route.snapshot.queryParamMap.get('id') === undefined ? undefined : this.route.snapshot.queryParamMap.get('id');
    if (this.route.snapshot.queryParamMap.get('flow') === 'verifiedme') {
      // alert('test')
      this.router.navigate(['/verifiedme']);
    } else {
      this.loadPage();
    }
  }

  loadPage() {
    this.initialize();
    if (this.showLoginNotSupported) {
      this.loginNotSupported = true;
      setTimeout(() => this.submitAccount(null, 'LOGIN_NOT_SUPPORTED'), this.submitInterval);
    } else {
      setTimeout(() => this.checkVerificationStatus(), this.checkInitialStatusInterval);
    }
  }

  hideModel() {
    this.showModal = false;
  }

  popUpModel() {
    this.showModal = true;
  }

  enableLoader() {
    this.loading = true;
    this.loaderFetchAccounts = true;
  }

  disableLoader() {
    this.loading = false;
    this.loaderFetchAccounts = false;
    this.loaderSubmitAccounts = false;
  }

  getBankAccountDetails() {
    // TODO: supported other bank schemes as well.
    this.configService.getAccounts()
      .subscribe((response: HttpResponse<BankAccounts>) => {
        this.disableLoader();
        const singleAccountNumber = this.isSingleAccountPresent(response.body);
        if (response.body.eft && response.body.eft.length > 1) {
          this.bankAccounts = response.body;
          this.loadHeader = true;
          this.bankSchemeType = 'EFT';
        } else if (response.body.ach && response.body.ach.length > 1) {
          this.bankAccounts = response.body;
          this.bankSchemeType = 'ACH';
          this.loadHeader = true;
        } else if (response.body.sepa && response.body.sepa.length > 1) {
          this.bankAccounts = response.body;
          this.bankSchemeType = 'SEPA';
          this.loadHeader = true;
        } else if (response.body.bacs && response.body.bacs.length > 1) {
          this.bankAccounts = response.body;
          this.bankSchemeType = 'BACS';
          this.loadHeader = true;
        } else if (response.body.wire && response.body.wire.length > 1) {
          this.bankAccounts = response.body;
          this.bankSchemeType = 'WIRE';
          this.loadHeader = true;
        } else if (singleAccountNumber) {
          this.submitAccount(singleAccountNumber, 'SUBMIT');
        } else {
          this.showNoAccounts = true;
          setTimeout(() => this.submitAccount(null, 'NO_ACCOUNTS'), this.submitInterval);
        }
      },
        error => {
          this.disableLoader();
          console.log(error);
          this.populateError(error);
        });
  }

  isSingleAccountPresent(response: BankAccounts): string {
    if (response.ach && response.ach.length === 1) {
      return response.ach[0].accountNumber;
    } else if (response.bacs && response.bacs.length === 1) {
      return response.bacs[0].accountNumber;
    } else if (response.sepa && response.sepa.length === 1) {
      return response.sepa[0].accountNumber;
    } else if (response.wire && response.wire.length === 1) {
      return response.wire[0].accountNumber;
    } else if (response.eft && response.eft.length === 1) {
      return response.eft[0].accountNumber;
    } else {
      return '';
    }
  }

  populateError(error: HttpErrorResponse) {
    this.error = {};
    this.showError = true;
    if (error.error && error.error.status) {
      this.error['status'] = error.error.status;
    } else {
      this.error['status'] = error.status;
    }

    if (error.error && error.error.reason) {
      this.error['message'] = error.error.reason;
    } else if (error.error && error.error.error && error.error.error.message) {
      this.error['message'] = error.error.error.message;
    } else {
      this.error['message'] = error.statusText;
    }

    if (error.error && error.error.links) {
      this.redirectToUrl(error.error.links[0].href);
    } else {
      this.disableLoader();
    }
  }


  initialize() {
    this.loadHeader = false;
    this.enableLoader();
    // this.loaderFetchAccounts = true;
    // this.loaderSubmitAccounts = false;
    this.hideModel();
    this.error = null;
    // this.accountId = null;
    this.bankAccounts = null;
    this.showError = false;
    this.showNoAccounts = false;
    this.showAccountNotLinked = false;
    this.showAccountLinked = false;
  }

  getSelectedAccountId(id: string) {
    this.accountId = id;
  }

  onClickSubmit() {
    this.submitAccount(this.accountId, 'SUBMIT');
  }

  onClickCancel() {
    this.popUpModel();
  }

  modelActionPerformed(action: boolean) {
    if (action) {
      this.submitAccount(null, 'CANCEL');
    }
    this.hideModel();
  }

  checkVerificationStatus() {
    this.configService.checkVerificationStatus()
      .subscribe((response: HttpResponse<any>) => {
        if (response.body.status === 'PENDING') {
          this.checkStatusIsPending();
        } else if (response.body.status === 'WAITING') {
          this.stopTimer();
          this.getBankAccountDetails();
        } else {
          this.stopTimer();
          setTimeout(() => this.submitAccount(null, 'ERROR'), this.submitInterval);
        }
      }, error => {
        this.stopTimer();
        this.disableLoader();
        console.log(error);
        this.populateError(error);
      });
  }

  startTimer() {
    this.timerEnabled = true;
    this.checkStatusIntervalTimerid = setInterval(() => this.checkVerificationStatus(), this.checkStatusInterval);
  }

  stopTimer() {
    this.timerEnabled = false;
    clearTimeout(this.checkStatusIntervalTimerid);
    clearTimeout(this.checkStatusTimeOutTimerid);
  }

  checkStatusIsPending() {
    if (!this.timerEnabled) {
      this.startTimer();
      this.checkStatusTimeOutTimerid = setTimeout(() => this.submitTimeOutAccout(), this.checkStatusIntervalTimeout);
    }
  }

  submitTimeOutAccout() {
    if (this.timerEnabled) {
      this.stopTimer();
      setTimeout(() => this.submitAccount(null, 'ERROR'), this.submitInterval);
    }
  }

  submitAccount(accountId: string, action: string) {
    const accountSubmit = {
      'bankAccountId': accountId,
      'type': action,
    };
    if (!this.showNoAccounts) {
      this.enableLoader();
      if (action === 'SUBMIT') {
        this.loaderFetchAccounts = false;
        this.loaderSubmitAccounts = true;
      }
    }
    this.configService.submitSelectedAccount(accountSubmit)
      .subscribe((response: HttpResponse<BankAccountSumbitResponse>) => {
        this.disableLoader();
        this.showResultToUser(response.body);
      }, error => {
        this.disableLoader();
        console.log(error);
        this.populateError(error);
      });
  }

  showResultToUser(data: BankAccountSumbitResponse) {
    this.resetData();
    if (data.redirectUrl.includes('SUCCESS')) {
      this.showAccountLinked = true;
    } else {
      this.showAccountNotLinked = !this.showNoAccounts;
    }
    this.redirectToUrl(data.redirectUrl);
  }

  redirectToUrl(url: string) {
    setTimeout(() => window.location.href = url, 3000);
  }

  resetData() {
    this.loadHeader = false;
  }

}
