import { TestServiceService } from './../services/test-service.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-test-flow',
  templateUrl: './test-flow.component.html',
  styleUrls: ['./test-flow.component.css']
})
export class TestFlowComponent implements OnInit {
  authtoken: string;
  merrefnum: string;
  reqmode: string;
  firstName: string;
  middleName: string;
  lastName: string;
  lang: string;
  legalName: string;
  scheme: string;
  redirectUrl: string;
  appDomain: string;
  apiDomain: string;
  default: string;
  on_completed: string;
  on_failed: string;
  paramDomain: boolean;
  showIframe: boolean;
  success: boolean;
  failure: boolean;
  error: boolean;
  constructor(private testServiceService: TestServiceService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.appDomain = window.location.href.split('#')[0];
    this.apiDomain = window.location.href.split('#')[0].replace('4200', '8305');
    this.authtoken = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJQYXlzYWZlIFBMQyIsImF1dGhvcml0aWV'
      + 'zIjpbIkZDTlVTRVIiXSwianRpIjoiMGQ3OWM3YzctODJjNC00NWFhLTgxODMtODMyYjQ2NGJjODc0IiwiY2xpZW50X2lkIjoiM'
      + 'mJlNTE1MmUxNTEwNzE5ZGNiOWVlNGI0NDQzMDkwNzY4MThkMWY0MCIsInBheXNhZmVfc2NvcGVfZ3JvdXBfaWQiOiIzMDk1Njk'
      + 'wIiwicGF5c2FmZV9zY29wZV9ncm91cF90eXBlIjoiUE1MRSIsImlhdCI6MTU1NDU3OTE0NCwiZXhwIjoxNTU0NTgyNzQ0LCJwY'
      + 'XlzYWZlX3Njb3BlX2dyb3VwX3NvdXJjZSI6Ik5FVEJBTlgiLCJwYXlzYWZlX3Njb3BlX2FjY291bnRfaWQiOlsiMzA5NSJdfQ='
      + '=.ekFqJv5WZLHGunc0hTz6KgxQEcO2RXyQBzA55wLJ_po';

    this.merrefnum = '1234';
    this.reqmode = 'LIVE';
    this.firstName = 'John';
    this.middleName = 'Rich';
    this.lastName = 'Doe';
    this.legalName = 'legal name';
    this.default = this.appDomain + 'pages/success.html';
    this.on_completed = this.appDomain + 'pages/success.html';
    this.on_failed = this.appDomain + 'pages/error.html';
    const windowMessageListener = (event) => {
      if (this.showIframe) {
        if (event.data.eventType === 'success') {
          this.success = true;
          this.showIframe = false;
        } else if (event.data.eventType === 'cancel') {
          this.failure = true;
          this.showIframe = false;
        }
      }
      console.log(event.data);
    };
    window.onmessage = windowMessageListener;
    if (this.route.snapshot.queryParamMap.get('domain')) {
      this.paramDomain = true;
    } else {
      this.paramDomain = false;
    }
    this.showIframe = false;
    if (this.route.snapshot.queryParamMap.get('lang')) {
      this.lang = this.route.snapshot.queryParamMap.get('lang');
    } else {
      this.lang = 'en_CA';
    }
    this.scheme = 'EFT';
    console.log('selected language is: ' + this.lang);
  }

  onSubmit() {
    this.success = false;
    this.failure = false;
    this.error = false;
    const data = {
      'authToken': this.authtoken,
      'merchantRefNum': this.merrefnum,
      'reqMode': this.reqmode,
      'useSummary': true,
      'scheme': this.scheme,
      'profile': {
        'firstName': this.firstName,
        'middleName': this.middleName,
        'lastName': this.lastName,
        'locale': this.lang
      },
      'business': {
        'legalName': this.legalName
      },
      'returnLinks': [
        {
          'rel': 'default',
          'href': this.default
        },
        {
          'rel': 'on_completed',
          'href': this.on_completed
        },
        {
          'rel': 'on_failed',
          'href': this.on_failed
        }
      ]
    };
    this.testServiceService.getInternal(data, this.apiDomain).subscribe(rata => {
      console.log(rata);
      this.redirectUrl = rata['body']['links'][0]['href'];
      this.showIframe = true;
    }, error => { });
  }

  modelActionPerformed(action: boolean) {
    if (confirm('You are cancleing Operation')) {
      this.showIframe = false;
    }
  }

  addListner() {
    const windowMessageListener = (event) => {
      if (this.showIframe) {
        if (event.data.eventType === 'success') {
          this.success = true;
          window.onmessage = null;
          this.showIframe = false;
        } else if (event.data.eventType === 'cancel') {
          this.failure = true;
          window.onmessage = null;
          this.showIframe = false;
        }
      }
    };
    window.onmessage = windowMessageListener;
  }

}
