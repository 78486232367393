import { Component } from '@angular/core';

@Component({
  selector: 'app-no-accounts-linked',
  templateUrl: './no-accounts-linked.component.html',
  styleUrls: ['./no-accounts-linked.component.scss']
})
export class NoAccountsLinkedComponent {

  constructor() { }

}
