import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  @Output()
  userAction = new EventEmitter();

  constructor() { }

  onSubmit(action: boolean) {
    this.userAction.emit(action);
  }

}
