import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { TestServiceService } from '../services/test-service.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-flinks-model',
  templateUrl: './flinks-model.component.html',
  styleUrls: ['./flinks-model.component.scss']
})
export class FlinksModelComponent implements OnInit {

  @Input()
  redirectUrl: string;

  @Output()
  userAction = new EventEmitter();

  ngOnInit() {
  }

  constructor() {
  }

  onSubmit(action: boolean) {
    this.userAction.emit(action);
  }
}
