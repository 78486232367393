import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable()
export class TestServiceService {

  constructor(private http: HttpClient) { }

  getInternal(data: any,  host: string) {
    let url = 'internal/v1/addBank';
    if (host) {
      url = host + url;
    }
    return this.http.post<any>(url, data, { observe: 'response' });
  }

}
