<div class="content-wrapper">
  <img src="./assets/images/alert.svg" height="48px" width="48px">
  <h3>{{'ALERT' | translate}}</h3>
  <div class="content">
    <p>{{'NO_ACCOUNT_WILL_BE_LINKED' | translate}}</p>
    <p>{{'ARE_U_SURE' | translate}}</p>
  </div>
    <div class="button-wrapper">
        <div class="row">
            <div class="col-12">
              <button class="button btn btn-outline-primary" (click)="onSubmit(true)">{{'YES' | translate }}</button>
              <button class="button btn btn-primary" (click)="onSubmit(false)">{{'NO' | translate }}</button>
            </div>
        </div>
    </div>
</div>
<div class="modal-bg"></div>