import { ErrorRedirectComponent } from './error-redirect/error-redirect.component';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TestFlowComponent } from './test-flow/test-flow.component';
import { VerifiedmePageComponent } from './verifiedme-page/verifiedme-page.component';

const routes: Routes = [
  {
    path: 'bank',
    component: LandingPageComponent
  },
  {
    path: 'verifiedme',
    component: VerifiedmePageComponent
  },
  {
    path: 'test',
    component: TestFlowComponent
  },
  {
    path: 'error/:status',
    component: ErrorRedirectComponent
  },
  {
    path: '',
    component: PageNotFoundComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

export const AppRoutingModule: ModuleWithProviders<unknown> = RouterModule.forRoot(routes, { useHash: true,
  relativeLinkResolution: 'legacy' });
