<div class="bg-dark">
<div style="padding: 35px 60px 60px 80px;">
   <h1>Bank Account Integration</h1>
   <br/>
   <h3 style="margin-bottom: 15px;">Customer Information</h3>
   <label for="firstname" style="width: 95px;">
      <p>First Name*: </p>
   </label>
   <input name="firstname" type="text" [(ngModel)]="firstName" required style="margin-left:10px;"><br>
   <label for="middlename" style="width: 95px;">
      <p>Middle Name: </p>
   </label>
   <input name="middlename" type="text" [(ngModel)]="middleName"  style="margin-left:10px;"><br>
   <label for="lastname" style="width: 95px;">
      <p>Last Name*: </p>
   </label>
   <input name="lastname" type="text" [(ngModel)]="lastName" required  style="margin-left:10px;"><br>
   <label for="businessName" style="width: 95px;">
    <p>Business Legal Name*: </p>
 </label>
 <input name="businessName" type="text" [(ngModel)]="legalName" required  style="margin-left:10px;"><br>
   <label for="langauge" style="width: 95px;">
      <p>Language: </p>
   </label>
   <select name="langauge" [(ngModel)]="lang"  style="margin-left:10px;">
   <option  value="en_CA" selected>English</option>
   <option  value="fr_CA">French</option>
   </select><br>
   <label for="BankScheme" style="width: 95px;">
    <p>BankScheme: </p>
 </label>
   <select name="BankScheme" [(ngModel)]="scheme"  style="margin-left:10px;">
    <option  value="EFT" selected>EFT</option>
    <option  value="ACH">ACH</option>
    <option  value="WIRE">WIRE</option>
    <option  value="SEPA">SEPA</option>
    <option  value="BACS">BACS</option>
    </select><br>
   <!--  <h3>Click On Button to Add Bank</h3><br> -->
   <button (click)="onSubmit()">Add Bank</button>
   <br>
   <app-flinks-model *ngIf="showIframe" [redirectUrl]="redirectUrl" (userAction)="modelActionPerformed($event)"></app-flinks-model>
   <div style="margin-top:20px;">
      <div *ngIf="success">
         <h4>Account added successfully!</h4>
      </div>
      <div *ngIf="failure">
         <h4>Failed to add account!</h4>
      </div>
      <div *ngIf="error">
         <h4>Oops! Unexpected error occurred. Please try again after some time.</h4>
      </div>
   </div>
</div>

<div >
   <div>
      <label for="domain">
         <p>Domain: </p>
      </label>
      <input name="domain" type="text" [(ngModel)]="apiDomain" required style="margin-left:10px;">
   </div>
   <div>
      <label for="authtoken">
         <p>Auth-token*: </p>
      </label>
      <input name="authtoken" type="text" [(ngModel)]="authtoken" required style="margin-left:10px;">
   </div>
   <div>
      <label for="merrefnum">
         <p>Merchant-Ref-Num*: </p>
      </label>
      <input name="merrefnum" type="text" [(ngModel)]="merrefnum" required style="margin-left:10px;">
   </div>
   <div>
      <label for="reqmode">
         <p>Mode: </p>
      </label>
      <input name="reqmode" type="text" [(ngModel)]="reqmode" required style="margin-left:10px;">
   </div>
</div>
</div>
