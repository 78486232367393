import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-verifiedme-no-userinfo',
  templateUrl: './verifiedme-no-userinfo.component.html',
  styleUrls: ['./verifiedme-no-userinfo.component.scss']
})
export class VerifiedmeNoUserinfoComponent {

  @Input()
  loginNotSupported: boolean;

  constructor() { }

}
